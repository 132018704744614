<template>
  <div class="container">
    <div class="text-4xl">Что то пошло не так,<br />страница не найдена</div>
    <div>
      <a
        href="/get/"
        class="text-white bg-main-orange font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none"
        >На главную</a
      >
    </div>
  </div>
</template>
<style scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.text-4xl {
  text-align: center;
  margin-bottom: 20px;
}
</style>
