<template>
  <client-only>
    <modal
      :adaptive="true"
      :height="385"
      :width="828"
      class="agreement"
      name="agreement"
      @before-open="beforeOpen"
      @before-close="beforeClose"
    >
      <div class="scroll">
        <div class="scroll__wrapper">
          НАСТОЯЩИМ ВЫРАЖАЮ СОГЛАСИЕ<br />
          <br />
          Микрофинансовой компания «Быстроденьги» (Общество с ограниченной
          ответственностью) (ОГРН 1087325005899), расположенной по
          адресу:&nbsp;123290, Россия, город Москва, тупик Магистральный 1-й,
          дом 11, стр. 10&nbsp;(далее – «Общество») <br />
          <br />
          1. ПОЛУЧАТЬ ИНФОРМАЦИЮ РЕКЛАМНОГО ХАРАКТЕРА ОБ ОБЩЕСТВЕ ИЛИ ЕГО
          ПАРТНЕРАХ ОТ ОБЩЕСТВА И/ИЛИ ОТ МФО Турбозайм (ООО) (ОГРН
          1137746702367, ИНН 7702820127, КПП&nbsp;771401001);<br />
          <br />
          2. ОБРАБАТЫВАТЬ СЛЕДУЮЩИЕ МОИ ПЕРСОНАЛЬНЫЕ ДАННЫЕ: фамилия, имя,
          отчество; пол; дата и место рождения; паспортные данные, в том числе,
          сведения об адресе регистрации; идентификационный номер
          налогоплательщика; номер страхового свидетельства обязательного
          пенсионного страхования; место фактического проживания; семейное
          положение и количество детей; сведения о полученном образовании;
          сведения о форме занятости и среднем размере месячного дохода и
          расхода; дата предыдущей и следующей зарплаты; номера контактных
          телефонов; адрес электронной почты; сведения о наименовании
          работодателя, адресе места работы и должности; трудовом стаже и
          графике работы; а также иные данные, предоставляемые мной Обществу в
          процессе заполнения анкеты на сайте Общества.<br />
          <br />
          В случае предоставления мною Обществу персональных данных третьих лиц,
          я заявляю и гарантирую, что мною получено согласие этих лиц на
          передачу их персональных данных Обществу и обработку этих персональных
          данных Обществом.<br />
          <br />
          Мое согласие распространяется на обработку моих персональных данных
          Обществом В СЛЕДУЮЩИХ ЦЕЛЯХ:<br />
          <br />
          - рассмотрение Обществом моих заявок, при котором производится оценка
          моей потенциальной кредитоспособности и платёжеспособности;<br />
          <br />
          - осуществление и выполнение функций, полномочий и обязанностей
          Общества, возложенных на него законодательством Российской Федерации,
          а также прав и законных интересов Общества;<br />
          <br />
          - оказание мне Обществом финансовых и иных консультаций;<br />
          <br />
          - продвижение услуг Общества и /или партнеров Общества, включая МФО
          Турбозайм (ООО) (ОГРН 1137746702367, ИНН 7702820127,
          КПП&nbsp;771401001).<br />
          <br />
          Мое согласие распространяется на следующие ДЕЙСТВИЯ ОБЩЕСТВА ПО
          ОБРАБОТКЕ моих персональных данных, осуществленные в указанных выше
          целях: сбор, запись, хранение, уточнение (обновление, изменение),
          использование, передача третьим лицам (предоставление, доступ),
          обезличивание, блокирование, удаление и уничтожение, как с
          использованием средств автоматизации, так и без использования таких
          средств.<br />
          <br />
          Мое согласие распространяется на передачу Обществом моих персональных
          данных третьим лицам, в частности, МФО Турбозайм (ООО) (ОГРН
          1137746702367, ИНН 7702820127, КПП&nbsp;771401001), включая
          трансграничную передачу&nbsp;персональных данных на территории любых
          иностранных государств, если такая передача соответствует указанным
          выше целям обработки персональных данных.<br />
          <br />
          Настоящее Согласие действует в течение 1 (один) год.<br />
          <br />
          Настоящее Согласие может быть отозвано мной путем подачи Обществу
          соответствующего письменного заявления. Заявление об отзыве настоящего
          Согласия может быть подано только мной лично, для чего я должен
          (должна) явиться в Общество с документом, удостоверяющим личность, и
          подать сотруднику Общества соответствующее заявление, либо направить
          заявление на отзыв Согласия на юридический адрес Общества, в таком
          заявлении должны быть указаны мои паспортные данные, а моя подпись
          заверена нотариально.<br />
          <br />
          Между мной и Обществом достигнуто соглашение о том, что в случае
          отзыва мною настоящего Согласия Общество вправе продолжать
          обрабатывать мои персональные данные в целях исполнения Обществом
          требований законодательства.<br />
          <br />
          Настоящим я признаю и подтверждаю, что в случае необходимости
          предоставления моих персональных данных для достижения указанных выше
          целей третьему лицу, передачи Обществом принадлежащих ему функций и
          полномочий иному лицу, а равно при привлечении третьих лиц к оказанию
          услуг в указанных целях Общество вправе в необходимом объеме
          раскрывать для совершения вышеуказанных действий информацию обо мне
          лично (включая мои персональные данные) таким третьим лицам, а также
          предоставлять таким лицам соответствующие документы, содержащие такую
          информацию.
        </div>
        <a
          class="scroll__close_modal"
          href="#"
          @click.prevent="stateShowAgreement = !stateShowAgreement"
          >Закрыть</a
        >
      </div>
    </modal>
  </client-only>
</template>

<script>
export default {
  name: "Agreement",
  computed: {
    stateShowAgreement: {
      get() {
        return this.$store.getters.getShowAgreement;
      },
      set(value) {
        this.$store.commit("toggleAgreement", value);
      },
    },
  },
  watch: {
    stateShowAgreement(val) {
      if (val) {
        this.$modal.show("agreement");
      } else {
        this.$modal.hide("agreement");
      }
    },
  },
  methods: {
    beforeClose() {
      this.stateShowAgreement = false;
      const el = document.body;
      el.classList.remove("overflow-hidden");
    },
    beforeOpen() {
      const el = document.body;
      el.classList.add("overflow-hidden");
    },
  },
};
</script>

<style lang="scss" scoped>
@use "Agreement";
</style>
