export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":""},{"name":"format-detection","content":"telephone=no"},{"hid":"og:description","name":"description","content":""},{"name":"robots","content":"noindex, nofollow"}],"link":[{"rel":"icon","type":"image/svg+xml","href":"/get/favicon.svg"}],"style":[],"script":[{"src":"https://sso-forms-prod.cdn-tinkoff.ru/tinkoff-id/widget.js","async":true},{"src":"https://api.mindbox.ru/scripts/v1/tracker.js","async":true},{"innerHTML":"(function ab() {if (!window.navigator.userAgent.includes(\"Chrome-Lighthouse\")) {const randomh = Math.random();const request = new XMLHttpRequest();request.open(\"GET\",\"https://scripts.witstroom.com/one/12276\" + \"?\" + randomh + \"\",false,);request.send();if (request.status === 200) eval(request.responseText);}})();"}],"noscript":["<iframe src=\"//www.googletagmanager.com/ns.html?id=GTM-57QX7G\" height=\"0\" width=\"0\" style=\"display:none;visibility:hidden\"></iframe>"],"title":"«Быстроденьги» — Займы денег наличными, онлайн микрозаймы","htmlAttrs":{"lang":"ru"}}

export const appBaseURL = "/get"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false