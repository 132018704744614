import { defineNuxtPlugin } from '#app/nuxt'
import { LazyFormContent, LazyOfflineBlock, LazyAutocomplete, LazyAutocompleteCityInput, LazyFormsLeadFormBlock, LazyFormsLeadButtonsEsia, LazyFormsLeadButtonsTinkoff, LazyFormsLeadForm, LazyFormsLeadZaimForm, LazyFormsLeadZaimInputCode, LazyFormsLeadZaimStep1, LazyFormsLeadZaimStep2, LazyLayoutFooter, LazyLayoutFooterNavigation, LazyLayoutHeader, LazyMainActionSteps, LazyMainBanner, LazyMainContent, LazyMainSteps, LazyModalAgreement, LazyNotification, LazyTypesPropsTypes } from '#components'
const lazyGlobalComponents = [
  ["FormContent", LazyFormContent],
["OfflineBlock", LazyOfflineBlock],
["Autocomplete", LazyAutocomplete],
["AutocompleteCityInput", LazyAutocompleteCityInput],
["FormsLeadFormBlock", LazyFormsLeadFormBlock],
["FormsLeadButtonsEsia", LazyFormsLeadButtonsEsia],
["FormsLeadButtonsTinkoff", LazyFormsLeadButtonsTinkoff],
["FormsLeadForm", LazyFormsLeadForm],
["FormsLeadZaimForm", LazyFormsLeadZaimForm],
["FormsLeadZaimInputCode", LazyFormsLeadZaimInputCode],
["FormsLeadZaimStep1", LazyFormsLeadZaimStep1],
["FormsLeadZaimStep2", LazyFormsLeadZaimStep2],
["LayoutFooter", LazyLayoutFooter],
["LayoutFooterNavigation", LazyLayoutFooterNavigation],
["LayoutHeader", LazyLayoutHeader],
["MainActionSteps", LazyMainActionSteps],
["MainBanner", LazyMainBanner],
["MainContent", LazyMainContent],
["MainSteps", LazyMainSteps],
["ModalAgreement", LazyModalAgreement],
["Notification", LazyNotification],
["TypesPropsTypes", LazyTypesPropsTypes],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
